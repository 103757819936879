import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map } from 'rxjs';

import { CatalogueItem, CatalogueItemDetails, CatalogueItemDetailsDto, CatalogueItemDto } from '../models';
import { ApiResponse } from '@app/shared/models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getItems() {
        return this.httpClient
            .get<ApiResponse<CatalogueItemDto[]>>('api/v1/catalogue/single?limit=999')
            .pipe(map((res) => res.data.map((dto) => new CatalogueItem(dto))));
    }

    getItemDetails(singleId: string) {
        return this.httpClient
            .get<ApiResponse<CatalogueItemDetailsDto>>(`api/v1/catalogue/single/${singleId}`)
            .pipe(map((res) => new CatalogueItemDetails(res.data)));
    }
}
