import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CatalogueItem, CatalogueItemDetails } from '../models';

export const singleCatalogueActions = createActionGroup({
    source: 'Single Catalogue',
    events: {
        'Get Items': emptyProps(),
        'Get Items Success': props<{ items: CatalogueItem[] }>(),
        'Get Items Error': emptyProps(),

        'Get Item Details': props<{ id: string }>(),
        'Get Item Details Success': props<{ item: CatalogueItemDetails }>(),
        'Get Item Details Error': emptyProps(),

        'Clear Details Loaded': emptyProps(),
        Clear: emptyProps(),
    },
});
