import { CatalogueItemStatus, CatalogueItemType } from '../types';
import { CatalogueItemDto } from './catalogue-item.dto';

export class CatalogueItem {
    id: string | null;
    image: string | null;
    publishDate: Date | null;
    type: CatalogueItemType | null;
    name: string;
    artists: string;
    status: CatalogueItemStatus | null;

    constructor(dto: CatalogueItemDto) {
        this.id = dto.id ?? null;
        this.image = dto.image ?? null;
        this.publishDate = dto.publish_ts ? new Date(dto.publish_ts * 1000) : null;
        this.type = dto.type ?? null;
        this.name = dto.name ?? '';
        this.artists = dto.artists?.join(', ') ?? '';
        this.status = dto.status ?? null;
    }
}
