import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SINGLE_CATALOGUE_FEATURE_KEY } from './keys';
import { singleCatalogueReducer } from './reducers/single-catalogue.reducer';
import { SingleCatalogueEffects } from './effects/single-catalogue.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(SINGLE_CATALOGUE_FEATURE_KEY, singleCatalogueReducer), EffectsModule.forFeature([SingleCatalogueEffects])],
})
export class SingleCatalogueStoreModule {}
