import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, map, exhaustMap, switchMap } from 'rxjs';
import { singleCatalogueActions } from '../actions/single-catalogue.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class SingleCatalogueEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);

    getItems$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(singleCatalogueActions.getItems),
            exhaustMap(() => {
                return this.httpService.getItems().pipe(
                    map((items) => singleCatalogueActions.getItemsSuccess({ items })),
                    catchError(() => of(singleCatalogueActions.getItemsError())),
                );
            }),
        );
    });

    getItemDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(singleCatalogueActions.getItemDetails),
            switchMap(({ id }) => {
                return this.httpService.getItemDetails(id).pipe(
                    map((item) => singleCatalogueActions.getItemDetailsSuccess({ item })),
                    catchError(() => of(singleCatalogueActions.getItemDetailsError())),
                );
            }),
        );
    });

    clearDetailsLoaded$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(singleCatalogueActions.getItemDetailsSuccess, singleCatalogueActions.getItemDetailsError),
            map(() => singleCatalogueActions.clearDetailsLoaded()),
        );
    });
}
