import { createReducer, on } from '@ngrx/store';
import { singleCatalogueActions } from '../actions/single-catalogue.actions';
import { CatalogueItem, CatalogueItemDetails } from '../models';

export interface SingleCatalogueState {
    items: null | CatalogueItem[];
    loaded: null | boolean;

    details: null | CatalogueItemDetails;
    detailsLoaded: null | boolean;
}

export const initialState: SingleCatalogueState = {
    items: null,
    loaded: null,

    details: null,
    detailsLoaded: null,
};

export const singleCatalogueReducer = createReducer(
    initialState,
    on(singleCatalogueActions.getItems, (state): SingleCatalogueState => ({ ...state, loaded: null })),
    on(singleCatalogueActions.getItemsSuccess, (state, { items }): SingleCatalogueState => ({ ...state, items, loaded: true })),
    on(singleCatalogueActions.getItemsError, (state): SingleCatalogueState => ({ ...state, loaded: false })),

    on(singleCatalogueActions.getItemDetails, (state): SingleCatalogueState => ({ ...state, detailsLoaded: null })),
    on(singleCatalogueActions.getItemDetailsSuccess, (state, { item }): SingleCatalogueState => ({ ...state, details: item, detailsLoaded: true })),
    on(singleCatalogueActions.getItemDetailsError, (state): SingleCatalogueState => ({ ...state, detailsLoaded: false })),

    on(singleCatalogueActions.clearDetailsLoaded, (state): SingleCatalogueState => ({ ...state, detailsLoaded: null })),

    on(singleCatalogueActions.clear, (): SingleCatalogueState => ({ ...initialState })),
);
