import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { SingleCatalogueFacade } from '../facades/single-catalogue.facade';

export const singleCatalogueCleanup: CanDeactivateFn<unknown> = () => {
    const singleCatalogueFacade = inject(SingleCatalogueFacade);

    singleCatalogueFacade.clear();
    return true;
};
