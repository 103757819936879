import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SINGLE_CATALOGUE_FEATURE_KEY } from '../keys';
import { SingleCatalogueState } from '../reducers/single-catalogue.reducer';

const selectState = createFeatureSelector<SingleCatalogueState>(SINGLE_CATALOGUE_FEATURE_KEY);

export const selectItems = createSelector(selectState, (state) => state.items);
export const selectPendingItems = createSelector(selectState, (state) => state.items?.filter((item) => item.status === 'awaiting_approval'));
export const selectPlannedItems = createSelector(selectState, (state) => state.items?.filter((item) => item.status === 'scheduled'));
export const selectPublishedItems = createSelector(selectState, (state) => state.items?.filter((item) => item.status === 'published'));
export const selectLoaded = createSelector(selectState, (state) => state.loaded);

export const selectDetails = createSelector(selectState, (state) => state.details);
export const selectDetailsLoaded = createSelector(selectState, (state) => state.detailsLoaded);
