import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SingleCatalogueFacade } from '../facades/single-catalogue.facade';
import { filter, map, take, tap } from 'rxjs';
import { LoadersFacade } from '@app/store/loader';

export function catalogueDetailsLoaded(): CanActivateFn {
    return (activatedRoute) => {
        const singleCatalogueFacade = inject(SingleCatalogueFacade);
        const loadersFacade = inject(LoadersFacade);
        const router = inject(Router);

        const id: string = activatedRoute.params['id'];

        return singleCatalogueFacade.detailsLoaded$.pipe(
            tap((loaded) => {
                if (loaded === null) {
                    loadersFacade.add('catalogue-details');

                    singleCatalogueFacade.getItemDetails(id);
                }
            }),
            filter((loaded): loaded is boolean => loaded !== null),
            take(1),
            tap(() => loadersFacade.delete('catalogue-details')),
            map((loaded) => loaded || router.createUrlTree(['/distribution/catalogue'])),
        );
    };
}
