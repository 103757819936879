import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { selectLoaded } from '@app/store/user/selectors/user.selector';
import { singleCatalogueActions } from '../actions/single-catalogue.actions';
import {
    selectDetails,
    selectDetailsLoaded,
    selectItems,
    selectPendingItems,
    selectPlannedItems,
    selectPublishedItems,
} from '../selectors/single-catalogue.selector';

@Injectable({
    providedIn: 'root',
})
export class SingleCatalogueFacade {
    private readonly store = inject(Store);

    items$ = this.store.select(selectItems);
    pendingItems$ = this.store.select(selectPendingItems);
    plannedItems$ = this.store.select(selectPlannedItems);
    publishedItems$ = this.store.select(selectPublishedItems);
    loaded$ = this.store.select(selectLoaded);

    details$ = this.store.select(selectDetails);
    detailsLoaded$ = this.store.select(selectDetailsLoaded);

    getItems(): void {
        this.store.dispatch(singleCatalogueActions.getItems());
    }

    getItemDetails(id: string): void {
        this.store.dispatch(singleCatalogueActions.getItemDetails({ id }));
    }

    clear(): void {
        this.store.dispatch(singleCatalogueActions.clear());
    }
}
